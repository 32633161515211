<template>
  <div :class="[validationClass, 'form-field']">
    <label class="block">
      <span class="relative block">
        <span
          v-if="fieldLabel"
          class="label block"
        >
          {{fieldLabel}}
        </span>

        <select
          :value="value"
          :class="[!transparent
            ? 'pl-3 py-2.5 lg:py-3.5 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 rounded-md'
            : 'transparent',
            'pr-10 select'
          ]"
          @change="$emit('change', $event.target.value)"
        >
          <option disabled selected value="">
            {{disabledOptionValue}}
          </option>

          <option
            v-for="option in options"
            :key="`option-${option[optionLabel]}`"
            :value="option[trackBy]"
          >
            {{option[optionLabel]}}
          </option>
        </select>
      </span>
    </label>

    <p class="helper">
      <slot name="helper"></slot>
    </p>
  </div>
</template>

<script>
import FormFieldMixin from '@/mixins/FormFieldMixin';

export default {
  name: 'FormFieldSelect',

  mixins: [FormFieldMixin],

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    transparent: {
      type: Boolean,
      default: false
    },
    disabledOptionValue: {
      type: String,
      default: 'Select option'
    }
  }
};
</script>

<style scoped>
.select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  /*background-position: right .5rem center;*/
  background-position: right 6px center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  color-adjust: exact;
  appearance: none;
}

.select.transparent {
  @apply border-0 shadow-none bg-transparent
}
</style>
