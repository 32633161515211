<template>
  <div>
    <div class="bg-brand-lightest rounded-xl flex flex-col">
      <div class="heading flex justify-between">
        <h3 class="mb-0">
          ingredient
        </h3>

        <span class="inline-block text-grey-dark text-xs">
          Sum:
          <strong class="text-lg leading-none text-brand-semiMedium">{{ sum }}%</strong>
        </span>
      </div>

      <ul class="flex flex-col text-xs md:text-sm xl:text-base divide-gray-400 divide-opacity-30 divide-y">
        <li
          v-for="ingredient in $v.categoryWithoutMandatory.$each.$iter"
          class="flex items-center justify-between py-3 px-3.5 lg:px-7 xl:px-6 2xl:px-7 lg:py-5 text-brand-dark font-medium"
          :key="ingredient.id.$model"
        >
          <div class="w-1/4 md:w-auto">
            {{ingredient.name.$model}}
          </div>

          <div
            class="ingredient-field flex flex-1 md:flex-none items-center justify-between w-3/4 md:w-8/12"
            :class="{invalid: ingredient.cp.$invalid || hasCpError(ingredient), valid: !ingredient.cp.$invalid && !hasCpError(ingredient)}"
          >
            <input
              v-if="ingredient.id.$model === activeIngredientId"
              v-model="ingredient.cp.$model"
              v-toggle="{
                exclude: ['editCP'],
                handler: 'unactiveCP'
              }"
              type="number"
              class="ingredient-field-cp bg-transparent max-w-44px md:max-w-80px px-2"
              ref="editCP"
              :min="ingredient.cp_min.$model"
              :max="ingredient.cp_max.$model"
              @change="onChangeCp($event, ingredient)"
            />

            <div
              v-else
              class="flex flex-col"
              @click.stop="onEditCP(ingredient.id.$model)"
            >
              <span
                ref="editCP"
                class="text-brand-semiMedium underline uppercase text-xs md:text-sm whitespace-nowrap min-w-44px md:min-w-80px"
              >
               Cp: {{ingredient.cp.$model}}%
              </span>

              <p
                v-if="ingredient.id.$model !== activeIngredientId && (ingredient.cp.$invalid || hasCpError(ingredient))"
                class="helper"
              >
              <span class="invalid">
                Between {{ingredient.cp_min.$model}} and {{ingredient.cp_max.$model}}
              </span>
              </p>
            </div>

            <span
              class="reset-value"
              title="Reset to default"
              :class="{'is-hidden': ingredient.cp.$model === ingredient.cp_default.$model || ingredient.id.$model === activeIngredientId}"
              @click="setIngredientCp(ingredient, 'cp_default')"
            ></span>

            <form-field-input
              v-model="ingredient.percentage.$model"
              class="mx-3.5"
              type="number"
              ref="ingredientField"
              :validation-class="{invalid: ingredient.percentage.$error, valid: !ingredient.percentage.$invalid}"
              @blur="onCalculateSum"
            >
              <template v-slot:icon>%</template>

              <template #helper v-if="ingredient.percentage.$error">
                <span
                  v-if="!ingredient.percentage.required || !ingredient.percentage.between"
                  class="invalid"
                >
                  Between {{ingredient.percentage.$params.between.min}} and {{ingredient.percentage.$params.between.max}}
                </span>
              </template>
            </form-field-input>

            <button
              type="button"
              class="remove min-w-20px 2xl:min-w-44px"
              @click="$emit('removeIngredient', ingredient.id.$model)"
            >
              <span class="icon mr-0" v-html="icons.times"></span>
            </button>
          </div>
        </li>
      </ul>

      <form-field-select
        class="py-3 px-3.5 lg:px-7 xl:px-6 2xl:px-7 lg:py-5"
        v-if="isActiveIngredientsList"
        trackBy="id"
        optionLabel="name"
        :options="notSelectedIngredients"
        @change="onSetNewIngredient"
      />

      <button
        class="w-full text-center bg-brand-semiMedium text-white py-3 hover:bg-opacity-90"
        @click="isActiveIngredientsList = true"
      >
        <span class="icon mr-4" v-html="icons.plus"></span>

        Add ingredient
      </button>

      <template v-if="categoryWithMandatory.length">
        <ul class="text-xs md:text-sm xl:text-base">
          <li
            ref="categoryWithMandatory"
            class="flex items-center justify-between py-3 px-3.5 lg:px-7 xl:px-6 2xl:px-7 lg:py-5 border-b border-blue-light border-opacity-30 last:border-b-0 text-brand-dark font-medium"
          >
            <div class="w-1/4 md:w-auto">
              Oils / Fats
              <span
                class="icon w-4"
                v-html="icons.note"
                v-tooltip="'These values will be used for the calculation of cost savings'">
              </span>
            </div>

            <div class="flex flex-1 md:flex-none items-center justify-between w-3/4 md:w-8/12">
              <div class="min-w-44px md:min-w-80px"></div>

              <form-field-input
                class="mx-3.5"
                type="number"
                :value="categoryWithMandatorySum"
                :disabled="true"
              >
                <template v-slot:icon>%</template>
              </form-field-input>

              <button
                type="button"
                class="show min-w-20px 2xl:min-w-44px"
                @click="isOpenCategoryWithMandatory = !isOpenCategoryWithMandatory"
              >
                <span
                  v-html="icons.arrows.down"
                  :class="[isOpenCategoryWithMandatory ? 'transform -rotate-180' : '' , 'icon mr-0']"
                ></span>
              </button>
            </div>
          </li>
        </ul>

        <ul
          v-toggle="{
            exclude: ['categoryWithMandatory'],
            handler: 'closeCategoryWithMandatory'
          }"
          v-show="isOpenCategoryWithMandatory"
          class="text-xs md:text-sm xl:text-base font-medium py-5 flex space-y-2.5 flex-col border-t border-brand-dark bg-brand-semiLight"
        >
          <li
            v-for="ingredient in $v.categoryWithMandatory.$each.$iter"
            class="flex items-center justify-between px-3.5 lg:px-7 xl:px-6 2xl:px-7"
            :key="ingredient.id.$model"
          >
            <div class="w-1/4 md:w-auto text-brand-dark text-opacity-60">
              {{ingredient.name.$model}}
            </div>

            <div class="flex flex-1 md:flex-none items-center justify-between w-3/4 md:w-8/12">
              <div class="min-w-44px md:min-w-80px"></div>

              <form-field-input
                v-model="ingredient.percentage.$model"
                class="mx-3.5"
                type="number"
                ref="ingredientField"
                :validation-class="{invalid: ingredient.percentage.$error, valid: !ingredient.percentage.$invalid}"
                @blur="onCalculateSum"
              >
                <template v-slot:icon>%</template>

                <template #helper v-if="ingredient.percentage.$error">
                  <span
                    v-if="!ingredient.percentage.required || !ingredient.percentage.between"
                    class="invalid"
                  >
                    Between {{ingredient.percentage.$params.between.min}} and {{ingredient.percentage.$params.between.max}}
                  </span>
                </template>
              </form-field-input>

              <div class="spacer min-w-20px 2xl:min-w-44px"></div>
            </div>
          </li>
        </ul>
      </template>

      <ul class="text-xs md:text-sm xl:text-base divide-gray-400 divide-opacity-30 divide-y text-brand-dark font-medium">
        <li
          v-for="ingredient in $v.other.$each.$iter"
          class="flex items-center justify-between py-3 px-3.5 lg:px-7 xl:px-6 2xl:px-7 lg:py-5"
          :key="ingredient.id.$model"
        >
          <div class="w-1/4 md:w-auto">
            {{ ingredient.name.$model }}
          </div>

          <div class="flex flex-1 md:flex-none items-center justify-between w-3/4 md:w-8/12">
            <div class="min-w-44px md:min-w-80px"></div>

            <form-field-input
              v-model="ingredient.percentage.$model"
              class="mx-3.5"
              type="number"
              ref="ingredientField"
              :validation-class="{invalid: ingredient.percentage.$error, valid: !ingredient.percentage.$invalid}"
              @blur="onCalculateSum"
            >
              <template v-slot:icon>%</template>

              <template #helper v-if="ingredient.percentage.$error">
                <span
                  v-if="!ingredient.percentage.required || !ingredient.percentage.between"
                  class="invalid"
                >
                  Between {{ingredient.percentage.$params.between.min}} and {{ingredient.percentage.$params.between.max}}
                </span>
              </template>
            </form-field-input>

            <div class="spacer min-w-20px 2xl:min-w-44px"></div>
          </div>
        </li>

        <li class="flex items-center justify-between py-3 px-3.5 lg:px-7 xl:px-6 2xl:px-7 lg:py-5">
          <div class="w-1/4 md:w-auto">
            Sum
          </div>

          <div class="flex flex-1 md:flex-none items-center justify-between w-3/4 md:w-8/12">
            <div class="min-w-44px md:min-w-80px"></div>

            <form-field-input
              v-model="sum"
              class="mx-3.5"
              :disabled="true"
            >
              <template v-slot:icon>%</template>
            </form-field-input>

            <div class="spacer min-w-20px 2xl:min-w-44px"></div>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="sum > 100"
      class="mt-3 bg-red-300 border border-red-600 rounded-xl p-4 md:py-5 md:px-8 font-medium text-sm text-red-600"
    >
      <span
        class="icon md:mr-3.5"
        v-html="icons.info"
      ></span>

      Total inclusion cannot be more than 100%
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import FormFieldSelect from '@/components/form/FormFieldSelect';
import { validationMixin } from 'vuelidate';
import { between, required } from 'vuelidate/lib/validators';

export default {
  name: 'Ingredient',

  props: {
    value: {
      type: Array,
      required: true,
    }
  },

  mixins: [validationMixin],

  components: {
    FormFieldInput,
    FormFieldSelect
  },

  validations() {
    /* eslint-disable no-invalid-this */
    return {
      categoryWithoutMandatory: {
        $each: {
          name: '',
          cp: {
            required,
            between: between(0, 100)
          },
          cp_default: '',
          cp_min: '',
          cp_max: '',
          unique_identifier: '',
          id: '',
          percentage: {
            required,
            between: between(0, 100)
          }
        }
      },

      categoryWithMandatory: {
        $each: {
          name: '',
          id: '',
          percentage: {
            required,
            between: between(0, 100),
          }
        }
      },

      other: {
        $each: {
          name: '',
          id: '',
          percentage: {
            required,
            between: between(0, 100),
          }
        }
      }
    }
  },

  data: () => ({
    activeIngredientId: null,
    isOpenCategoryWithMandatory: false,
    isActiveIngredientsList: false,
    sum: 0
  }),

  methods: {
    reduceValue(arr, key) {
      return Math.round(arr.reduce((accum, current) => {
        return accum + (parseFloat(current[key]) || 0)
      }, 0) * 100) / 100
    },

    onCalculateSum() {
      this.sum = this.reduceValue(this.$refs.ingredientField, 'value')
      this.$emit('calculateSum', this.sum)
    },

    unactiveCP() {
      this.activeIngredientId = null
    },

    onEditCP(id) {
      this.activeIngredientId = id

      this.$nextTick(() => {
        this.$refs.editCP.forEach(el => {
          el.focus()
        })
      })
    },

    onSetNewIngredient(e) {
      this.$emit('addIngredient', e)

      this.isActiveIngredientsList = false
    },

    closeCategoryWithMandatory() {
      this.isOpenCategoryWithMandatory = false
    },

    hasCpError(ingredient) {
      const cp = ingredient.cp.$model;
      return cp < ingredient.cp_min.$model || cp > ingredient.cp_max.$model
    },

    async onChangeCp(event, ingredient) {
      if (!ingredient.cp.$invalid && !this.hasCpError(ingredient)) {
        this.setIngredientCp(ingredient)
      }
    },

    setIngredientCp(model, key = 'cp') {
      const ingredient = this.getIngredient(model)

      if (ingredient && model.cp) {
        ingredient.cp = model[key].$model
      }
    },

    getIngredient(model) {
      return this.value.find(ingredient => ingredient.unique_identifier === model.unique_identifier.$model)
    }
  },

  computed: {
    categoryWithoutMandatory() {
      return this.value.filter(el => {
        return !el.mandatory && !el.category
      })
    },

    categoryWithMandatory() {
      return this.value.filter(el => {
        return el.mandatory && el.category
      })
    },

    categoryWithMandatorySum() {
      return this.reduceValue(this.categoryWithMandatory, 'percentage')
    },

    other() {
      return [this.value.find(el => el.unique_identifier === 'other-additives')]
    },

    notSelectedIngredients() {
      return this.$store.state.feed.ingredients.filter(el => {
        return !this.value.find(element => {
          return element.id === el.id;
        });
      });
    }
  },

  watch: {
    value: {
      handler() {
        this.$nextTick(() => {
          this.onCalculateSum()
        })
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.heading {
  @apply uppercase text-medium leading-none font-semibold p-5 lg:p-7 border-b border-grey-dark text-center lg:text-left
}

.reset-value {
  cursor: pointer;
  background-image: url('~@/assets/images/icons/reset-value.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 26px;
  height: 14px;
  position: relative;
}

.reset-value.is-hidden {
  z-index: -1;
}
</style>
