import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      fetchDiets: 'feed/fetchDiets',
    }),

    onNavigationClick(type, routePrev, routeNext) {
      if (routeNext === 'feed-view') {
        this.fetchDiets()
      }
      this.$router.push({
        name: type === 'prev' ? routePrev : routeNext
      })
    }
  }
}
