<template>
  <div class="fixed right-0 bottom-0 left-0 z-30 bg-brand-dark">
    <div class="flex justify-between container mx-auto py-3.5 md:py-4">
      <div class="flex-1 flex justify-start items-center">
        <button
          class="btn-light"
          :disabled="prev.disabled"
          @click="$emit('click', 'prev')"
        >
          Previous
        </button>

      </div>
      <div class="flex-1 flex justify-center items-center">
        <div v-if="selectedProduct === 'proact360'" class="text-brand-light text-2xl lg:text-4xl">ProAct 360<span class="text-xs lg:text-sm align-text-top">™</span></div>
        <div v-if="selectedProduct === 'proact'" class="text-brand-light text-2xl lg:text-3xl">RONOZYME<span class="text-xs lg:text-sm align-text-top">®</span> ProAct</div>
      </div>
      <div class="flex-1 flex justify-end items-center">
        <div class="lg:mr-10">
          <slot name="save"></slot>
        </div>
        <button
          v-if="!next.isHidden"
          class="btn-light"
          :disabled="next.disabled"
          @click="$emit('click', 'next')"
        >
          Next
        </button>

        <slot name="export"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Navigation',

  props: {
    prev: {
      type: Object,
      default: () => ({
        disabled: false
      })
    },
    next: {
      type: Object,
      default: () => ({
        disabled: false,
        isHidden: false
      })
    }
  },

  mounted() {
    this.$store.commit('setNavigation', true)
  },

  computed: {
    ...mapState({
      selectedProduct: state => state?.productSelect?.selectedProduct || {}
    }),
  }
};
</script>

<style scoped>

</style>
