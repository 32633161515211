<template>
  <div>
    <div class="bg-white rounded-xl flex flex-col">
      <h3 class="heading">
        Nutrients
      </h3>

      <ul class="text-xs md:text-sm xl:text-base divide-y divide-gray-400 divide-opacity-30">
        <li
          v-for="(nutrient, i) in value"
          :key="nutrient.id"
          class="flex justify-between text-brand-dark font-medium"
        >
          <div :class="[i === 2 ? 'items-end' : 'items-center', 'py-3 px-3.5 md:pl-7 md:pr-5 flex flex-1 justify-between']">
            <div
              :class="[nutrient.nutrientName.toLowerCase() === 'cp' ? 'text-brand-semiMedium' : '', 'pr-3']">
              {{nutrient.displayName}}
            </div>

            <form-field-input
              v-model="nutrient.value"
              ref="nutrient"
              type="number"
              class="w-8/12"
              :value="nutrient.value"
              @input="onInput(nutrient, 'nutrient')"
            >
              <template v-slot:icon>%</template>
            </form-field-input>
          </div>

          <div class="w-5/12 xl:w-4/12 2xl:w-44 bg-blue-light bg-opacity-40 py-3 px-3.5">
            <p v-if="i === 2" class="bg-brand-lightest text-baseColor text-xs -mt-3 -mx-3.5 mb-3 flex justify-between">
              <span class="p-1 md:px-4 md:py-3 leading-8 md:leading-normal">
                Ratio to d.Lys
              <span
                class="icon w-4 ml-1"
                v-html="icons.question"
                v-tooltip="'Amino acid default ratios are based on Brazilian tables 2017'">
              </span>
              </span>

              <button
                type="button"
                :class="[disabledRatioFields ? 'bg-brand-semiMedium' : 'bg-brand-mediumLightest', 'padlock text-white w-8 md:w-10 p-1.5 md:p-2.5']"
                @click="disabledRatioFields = !disabledRatioFields"
              >
                <span
                  class="icon mr-0"
                  v-html="disabledRatioFields ? icons.padlock.lock : icons.padlock.unlock"
                ></span>
              </button>
            </p>

            <form-field-input
              v-if="'ratio' in nutrient && nutrient.ratio !== null"
              v-model="nutrient.ratio"
              ref="ratio"
              type="number"
              step="1"
              :isResetValue="hasRestButtonRatio(nutrient) && !disabledRatioFields"
              :disabled="disabledRatioFields"
              @reset-value="onRestRatio(nutrient)"
              @input="onInput(nutrient, 'ratio')"
            >
              <template v-slot:icon>%</template>
            </form-field-input>
          </div>
        </li>
      </ul>
    </div>

    <p class="mt-5 text-center text-sm text-gray-600">
      <span
        class="icon w-4 mb-1"
        v-html="icons.note"
      />

      Amino acid default ratios are based on Brazilian tables 2017
    </p>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import {mapGetters} from 'vuex';

export default {
  name: 'Nutrients',

  props: {
    value: {
      type: Array,
      required: true,
    }
  },

  components: {
    FormFieldInput
  },

  data: () => ({
    disabledRatioFields: true,
    nutrientNames: {
      CP: 'cp',
      D_LYS: 'lys',
    }
  }),

  methods: {
    hasRestButtonValue(nutrient) {
      const ratio = nutrient[this.getActiveRatioByAnimalType]
      if (!ratio) {
        return false
      }

      return Number(nutrient.value).toFixed(3) !== this.getValueByFormula(ratio);
    },
    hasRestButtonRatio(nutrient) {
      const value = nutrient[this.getActiveRatioByAnimalType]
      if (!value) {
        return false
      }

      return Number(nutrient.ratio).toFixed(3) !== this.getRatioByFormula(this.getValueByFormula(nutrient[this.getActiveRatioByAnimalType]));
    },
    getValueByFormula(ratio) {
      const D_LYS_VAL = this.getNutrientByName(this.nutrientNames.D_LYS).value
      return ((D_LYS_VAL * ratio) / 100).toFixed(3);
    },
    getRatioByFormula(value) {
      const D_LYS_VAL = this.getNutrientByName(this.nutrientNames.D_LYS).value
      const objValue = ((value * 100) / D_LYS_VAL);
      return Number(objValue) ? objValue.toFixed(3) : objValue;
    },
    getNutrientByName(name) {
      return this.value.find(el => {
        return el.nutrientName.toLowerCase().includes(name.toLowerCase()) ? el : null
      })
    },
    onInput(nutrient, fieldType) {
      const {CP, D_LYS} = this.nutrientNames;

      const D_LYS_VAL = this.getNutrientByName(D_LYS).value

      const calculateValue = (obj = nutrient, dLysField) => {
        if (!obj.value.toString() && dLysField) {
          obj.value = 0
          return
        }

        if (obj.ratio && dLysField) {
          if (obj.value && !this.disabledRatioFields) {
            obj.ratio = this.getRatioByFormula(obj.value);

            return
          }

          obj.value = this.getValueByFormula(obj.ratio)
          return
        }

        if (fieldType === 'nutrient') {
          obj.ratio = this.getRatioByFormula(obj.value);
        }

        if (fieldType === 'ratio') {
          obj.value = this.getValueByFormula(obj.ratio)
        }
      }

      if (nutrient.nutrientName.toLowerCase().includes(D_LYS)) {
        for (const i in this.value) {
          if (Object.prototype.hasOwnProperty.call(this.value, i)) {
            if (this.value[i].nutrientName.toLowerCase() === CP || this.value[i].ratio === null) {
              continue
            }

            if (!D_LYS_VAL) {
              this.value[i].value = 0
            }
            calculateValue(this.value[i], true)
          }
        }
      } else if (nutrient.nutrientName.toLowerCase() !== CP) {
        calculateValue()
      }

      this.$emit('input', this.value)
    },
    onRestValue(nutrient) {
      nutrient.value = Math.round(this.getValueByFormula(nutrient[this.getActiveRatioByAnimalType]));
      this.onInput(nutrient, 'nutrient')
    },
    onRestRatio(nutrient) {
      nutrient.ratio = Math.round(this.getRatioByFormula(this.getValueByFormula(nutrient[this.getActiveRatioByAnimalType])));
      this.onInput(nutrient, 'ratio')
    }
  },
  computed: {
    ...mapGetters({
      getActiveRatioByAnimalType: 'animalType/getActiveRatioByAnimalType'
    })
  }
};
</script>

<style scoped>
.heading {
  @apply uppercase text-medium leading-none font-semibold p-5 lg:p-7 border-b border-grey-dark text-left
}

</style>
