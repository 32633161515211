export default {
  data: () => ({
    isModalOpen: false,
    // NOTE: get id of clicked item for delete
    activeItemIdPreparedForDelete: null
  }),

  methods: {
    onModalOpen() {
      this.isModalOpen = true
      this.emitOpenModal()
    },
    emitOpenModal() {
      this.$emit('openModal')
    },

    onModalClose() {
      this.isModalOpen = false
      this.activeItemIdPreparedForDelete = null
      this.emitCloseModal()
    },
    emitCloseModal() {
      this.$emit('closeModal')
    }
  }
}
