<template>
  <div>
    <div class="bg-white rounded-xl flex flex-col">
      <h3 class="heading">
        {{ name }} - Results
      </h3>

      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <div class="align-middle inline-block min-w-full">
            <div class="overflow-hidden">
              <table class="min-w-full text-center leading-none text-brand-dark">
                <thead class="bg-brand-lightest text-xs uppercase text-black">
                  <tr>
                    <th class="p-4 xl:px-1 xl:py-3 2xl:p-4">Nutrient</th>
                    <th class="p-4 xl:px-1 xl:py-3 2xl:p-4">Effect (%)</th>
                    <th class="p-4 xl:px-1 xl:py-3 2xl:p-4">Contribution (%)</th>
                    <th class="p-4 xl:px-1 xl:py-3 2xl:p-4">Matrix (%)</th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-grey-mediumLight xl:text-sm 2xl:text-md">
                  <tr
                    v-if="!value.length"
                  >
                    <td
                      class="p-4 xl:p-3 2xl:p-4"
                      v-for="count in columnsCount"
                      :key="`loading-item-${count}`"
                    >
                      <div class="animate-pulse">
                        <div class="">
                          <div class="h-4 bg-brand-dark bg-opacity-50 rounded"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(nutrient, i) in value"
                    :key="nutrient.unique"
                    :class="[i % 2 === 0 ? 'bg-white' : 'bg-blue-light bg-opacity-20']"
                  >
                    <td class="p-4 xl:p-3 2xl:p-4">
                      {{ nutrient.nutrient }}
                    </td>
                    <td
                      class="p-4 xl:p-3 2xl:p-4"
                      v-html="nutrient.effect"
                    ></td>
                    <td class="p-4 xl:p-3 2xl:p-4">
                      <template
                        v-if="nutrient.id === 'Energy1'"
                      >
                        <form-field-input
                          v-model="$v.value.$each.$iter[i].contribution.$model"
                          size="small"
                          type="number"
                          :validation-class="{invalid: $v.value.$each.$iter[i].contribution.$error, valid: !$v.value.$each.$iter[i].contribution.$invalid}"
                          @input="onInput(i)"
                        >
                          <template #helper v-if="$v.value.$each.$iter[i].contribution.$error">
                            <span
                                v-if="!$v.value.$each.$iter[i].contribution.required || !$v.value.$each.$iter[i].contribution.between"
                                class="invalid"
                            >
                              Between {{$v.value.$each.$iter[i].contribution.$params.between.min}} and {{$v.value.$each.$iter[i].contribution.$params.between.max}}
                            </span>
                          </template>
                        </form-field-input>
                      </template>
                      <template
                          v-else
                      >
                        {{ nutrient.contribution }}
                      </template>
                    </td>
                    <td class="p-4 xl:p-3 2xl:p-4">
                      {{ nutrient.matrix }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="mt-5 ml-1 mr-1 text-sm text-gray-600">
      <span
          class="icon w-4 mb-1"
          v-html="icons.note"
      />
      When you use this value matrix in combination with other enzymes or if you have any doubt, contact
      the DSM technical manager for any recommendation
    </p>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput';
import { validationMixin } from 'vuelidate';
import {between, required} from 'vuelidate/lib/validators';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'Result',

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    }
  },

  mixins: [validationMixin],

  validations: {
    value: {
      $each: {
        contribution: {
          required,
          between: between(1, 999999),
        }
      }
    },
  },

  components: {
    FormFieldInput
  },

  data: () => ({
    columnsCount: 4
  }),

  computed: {
    ...mapState({
      activeDiet: state => state.feed.activeDiet
    }),
    phaseResult() {
      return this.$store.state.phase.phaseResult || []
    },
    dose() {
      return this.activeDiet.diet_phases && this.activeDiet.diet_phases.length && this.activeDiet.diet_phases[0].dose
    }
  },

  methods: {
    ...mapMutations({
      'setPhaseResult': 'phase/setPhaseResult'
    }),
    onInput(i) {
      if (!this.$v.value.$each.$iter[i].contribution.$error) {
        const value = this.value
        this.setPhaseResult(value)
        this.$emit('change-nutrient', this.value[i].contribution)
      }
    }
  }
};
</script>

<style scoped>
.heading {
  @apply uppercase text-medium leading-none font-semibold p-5 lg:p-7 border-b border-grey-dark text-left
}
</style>
