function windowUnload(e) {
  e.preventDefault();
  e.returnValue = ''
}

export default {
  mounted() {
    window.addEventListener('beforeunload', windowUnload)
  },

  destroyed() {
    window.removeEventListener('beforeunload', windowUnload)
  },
}
